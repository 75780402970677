<template>
  <div>
  </div>
</template>

<script>

export default {
  name: "PageNotFound",
  components: {
  },
  methods: {
  }

}
</script>

<style scoped>


</style>