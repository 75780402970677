const servers =  [
    {
        path: '/servers',
        name: 'servers.index',
        component: () => import('@/pages/Server/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/servers/create',
        name: 'servers.create',
        component: () => import('@/pages/Server/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
]

export default servers