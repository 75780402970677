const email =  [
    {
        path: '/email/verification/pending',
        name: 'email.verification.pending',
        component: () => import('@/pages/Email/PendingPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: "Emailni tasdiqlash",
        }
    },
    {
        path: '/email/verification/already-success',
        name: 'email.verification.already_success',
        component: () => import('@/pages/Email/AlreadySuccessPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: "Emailni tasdiqlash",
        }
    },

    {
        path: '/email/verification/not-verified',
        name: 'email.verification.not_verified',
        component: () => import('@/pages/Email/NotVerifiedPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: "Emailni tasdiqlash",
        }
    },]

export default email