import email from "@/router/email";
import tickets from "@/router/tickets";
import settings from "@/router/settings";
import servers from "@/router/servers";
import clouds from "@/router/clouds";
import users from "@/router/users";

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/github-sign-in',
        name: 'impersonate',
        component: () => import('@/pages/Index/GithubSignInPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: "Redirecting",
        }
    },

    {
        path: '/settings/profile/github',
        name: 'impersonate2',
        component: () => import('@/pages/Index/GithubLinkPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: "Redirecting",
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Index/LoginPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/Index/RegisterPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: "Ro'yxatdan o'tish",
        }
    },
    ...email,
    ...tickets,
    ...settings,
    ...servers,
    ...clouds,
    ...users,
    {
        path: '/complete-registration',
        name: 'complete_registration',
        component: () => import('@/pages/Index/CompleteRegistration.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Dashboard",
        }
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/pages/Index/DashboardPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Dashboard",
        }
    },

]

export default routes