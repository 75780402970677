<template>
  <div>
  </div>
</template>

<script>

export default {
  name: "PageForbidden",
  components: {
  },
  methods: {

  }

}
</script>

<style scoped>


</style>