<template>
    <v-app>
        <component :is="resolveLayoutVariant">
            <!--    <v-slide-x-transition mode="out-in">-->
            <!--        <transition name="transition" mode="out-in" appear>-->
            <router-view v-if="!(not_found || forbidden)"></router-view>
            <page-not-found v-else-if="not_found"></page-not-found>
            <page-forbidden v-else-if="forbidden"></page-forbidden>

            <!--        </transition>-->
            <!--    </v-slide-x-transition>-->
        </component>
    </v-app>
</template>

<script>
import PageNotFound from "@/pages/Page/PageNotAllowed.vue";
import PageForbidden from "@/pages/Page/PageForbidden.vue";

export default {
    name: 'App',

    components: {
        PageForbidden,
        PageNotFound,
        LayoutBase: () => import('@/layouts/LayoutBase'),
        LayoutNavbar: () => import('@/layouts/LayoutNavbar'),
        LayoutContent: () => import('@/layouts/LayoutContent'),
    },
    async mounted() {
        if (this.$store.getters.token && !["/email/verification/success", "/email/verification/pending", "/email/verification/not-verified"].includes(window.location.pathname)) {
            await this.$store.dispatch('check_me', this.$store.getters.token)
            this.$i18n.locale = this.$store.getters.auth_user?.locale ?? "uz"
            this.$vuetify.theme.dark = this.$store.getters.auth_user?.theme === "dark"
            this.$vuetify.theme.light = this.$store.getters.auth_user?.theme === "light"
        } else {
            if (!["/login", "/github-sign-in"].includes(window.location.pathname))
                this.$router.push('/login')
            else{
                console.log(1111);
            }
        }
    }
    ,
    data() {
        return {
            not_found: false,
            forbidden: false,
        }
    }
    ,
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || 'Unicon.uz';
                this.not_found = false
                this.forbidden = false
            }
        }
        ,
    }
    ,
    methods: {}
    ,
    computed: {
        resolveLayoutVariant() {
            if (this.$route.meta.layout == 'content')
                return 'layout-content'
            return 'layout-navbar'
        }
    }
    ,
}
;
</script>
<style>

</style>