import axios from 'axios'

const state = {
    user: {},
    users: {},
    user_errors: {},
    user_loading: false,
}

const getters = {
    user: state => state.user,
    users: state => state.users,
    user_errors: state => state.user_errors,
    user_loading: state => state.user_loading,
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setUsers(state, users) {
        state.users = users
    },
    setUserErrors(state, user_errors) {
        state.user_errors = user_errors
    },
    setUserLoading(state, user_loading) {
        state.user_loading = user_loading
    },
}

const actions = {
    async storeUser({commit}, data) {
        commit('setUserLoading', true)
        commit('setUserErrors', {})
        try {
            let response = await axios.post(`/api/users/store`, data)
            commit('setUserLoading', false)
            commit('setUserErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setUserLoading', false)
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async sendUserMessage({commit}, data) {
        commit('setUserLoading', true)
        commit('setUserErrors', {})
        try {
            let response = await axios.post(`/api/users/${data.user_id}/send-message`, data.data)
            commit('setUserLoading', false)
            commit('setUserErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setUserLoading', false)
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async getUsers({commit}, data) {
        try {
            let response = await axios.get(`/api/users`, data)
            commit('setUsers', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
                return false
        }
    },

    async getUser({commit}, id) {
        try {
            let response = await axios.get(`/api/users/${id}/show`)
            commit('setUser', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getTopics({commit}, data) {
        try {
            let response = await axios.get(`/api/topics`, data)
            commit('setTopics', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async selectUser({commit}, id) {
        commit('setUserLoading', true)
        try {
            let response = await axios.post(`/api/users/${id}/select`)
            commit('setUserLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async updateUser({commit}, data) {
        commit('setUserLoading', true)
        commit('setUserErrors', {})
        try {
            let response = await axios.put(`/api/users/${data.id}/update`, data)
            commit('setUserLoading', false)
            commit('setUserErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setUserLoading', false)
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}