import axios from 'axios'

const state = {
    individual_entity: {},
    individual_entity_errors: {},
    individual_entity_loading: false,
}

const getters = {
    individual_entity: state => state.individual_entity,
    individual_entity_errors: state => state.individual_entity_errors,
    individual_entity_loading: state => state.individual_entity_loading,
}

const mutations = {
    setIndividualEntity(state, individual_entity) {
        state.individual_entity = individual_entity
    },
    setIndividualEntityErrors(state, individual_entity_errors) {
        state.individual_entity_errors = individual_entity_errors
    },
    setIndividualEntityLoading(state, individual_entity_loading) {
        state.individual_entity_loading = individual_entity_loading
    },
}

const actions = {
    async IndividualEntityStore({commit}, data) {
        commit('setIndividualEntityLoading', true)
        commit('setIndividualEntityErrors', {})
        try {
            let response = await axios.post(`/api/entities/individual/store`,data)
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', error.response.data.errors)
            return false
        }
    },
    async IndividualEntitySendVerificationCode({commit}, data) {
        commit('setIndividualEntityLoading', true)
        commit('setIndividualEntityErrors', {})
        try {
            let response = await axios.post(`/api/entities/individual/verification-code`,data)
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', error.response.data.errors)
            return false
        }
    },

    async updateIndividualEntity({commit}, data) {
        commit('setIndividualEntityLoading', true)
        commit('setIndividualEntityErrors', {})
        try {
            let response = await axios.put(`/api/entities/individual/update`,data)
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', error.response.data.errors)
            return false
        }
    },

    async updateLegalEntity({commit}, data) {
        commit('setIndividualEntityLoading', true)
        commit('setIndividualEntityErrors', {})
        try {
            let response = await axios.put(`/api/entities/legal/update`,data)
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', error.response.data.errors)
            return false
        }
    },

    async verifyIndividual({commit}, data) {
        commit('setIndividualEntityLoading', true)
        commit('setIndividualEntityErrors', {})
        try {
            let response = await axios.post(`/api/entities/individual/verify`,data)
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setIndividualEntityLoading', false)
            commit('setIndividualEntityErrors', error.response.data.errors)
            return false
        }
    },



}

export default {
    state,
    getters,
    mutations,
    actions
}