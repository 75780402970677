const users =  [
    {
        path: '/user-management',
        name: 'users.index',
        component: () => import('@/pages/UserManagement/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Dashboard",
        }
    },
    {
        path: '/user-management/create',
        name: 'users.create',
        component: () => import('@/pages/UserManagement/CreatePage.vue'),
        meta: {
            need_project: false,
            requiresAuth: true,
            layout: 'content',
            title: "Tiket yaratish",
        }
    },
    {
        path: '/user-management/:id/show',
        name: 'users.show',
        component: () => import('@/pages/UserManagement/EditPage.vue'),
        meta: {
            need_project: false,
            requiresAuth: true,
            layout: 'content',
            title: "Tiket yaratish",
        }
    },
]

export default users