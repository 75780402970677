const email =  [
    {
        path: '/settings/profile',
        name: 'settings.profile',
        component: () => import('@/pages/Settings/ProfilePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/settings/panel',
        name: 'settings.panel',
        component: () => import('@/pages/Settings/PanelPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/settings/change-password',
        name: 'settings.change_password',
        component: () => import('@/pages/Settings/ChangePasswordPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/settings/login-history',
        name: 'settings.login_history',
        component: () => import('@/pages/Settings/LoginHistoryPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
]

export default email