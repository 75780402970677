import Vue from 'vue'
import vuetify from "@/plugins/vuetify";
import App from "@/App.vue";
import FlagIcon from 'vue-flag-icon'
import axios from "axios";
import i18n from "@/i18n";
import router from '@/router'
import store from "@/store";
import VueTheMask from 'vue-the-mask'

import '@/assets/css/main.css'

window.axios = axios;

Vue.use(FlagIcon)
Vue.use(VueTheMask)

const HelloJs = require('hellojs/dist/hello.all.min.js');
const VueHello = require('vue-hellojs');

HelloJs.init({
    google: process.env.VUE_APP_GOOGLE_CLIENT_ID,
}, {
    scope: "profile email",
    redirect_uri: window.location.origin + "/login"
});
Vue.use(VueHello, HelloJs);
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = "Bearer " + store.getters.token;
    return config;
});