import axios from 'axios'

const state = {
    ticket: {},
    tickets: {},
    topics: {},
    ticket_errors: {},
    ticket_loading: false,
}

const getters = {
    ticket: state => state.ticket,
    tickets: state => state.tickets,
    topics: state => state.topics,
    ticket_errors: state => state.ticket_errors,
    ticket_loading: state => state.ticket_loading,
}

const mutations = {
    setTicket(state, ticket) {
        state.ticket = ticket
    },
    setTickets(state, tickets) {
        state.tickets = tickets
    },
    setTopics(state, topics) {
        state.topics = topics
    },
    setTicketErrors(state, ticket_errors) {
        state.ticket_errors = ticket_errors
    },
    setTicketLoading(state, ticket_loading) {
        state.ticket_loading = ticket_loading
    },
}

const actions = {
    async storeTicket({commit}, data) {
        commit('setTicketLoading', true)
        commit('setTicketErrors', {})
        try {
            let response = await axios.post(`/api/tickets/store`, data)
            commit('setTicketLoading', false)
            commit('setTicketErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setTicketLoading', false)
            commit('setTicketErrors', error.response.data.errors)
            return false
        }
    },
    async sendTicketMessage({commit}, data) {
        commit('setTicketLoading', true)
        commit('setTicketErrors', {})
        try {
            let response = await axios.post(`/api/tickets/${data.ticket_id}/send-message`, data.data)
            commit('setTicketLoading', false)
            commit('setTicketErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setTicketLoading', false)
            commit('setTicketErrors', error.response.data.errors)
            return false
        }
    },
    async getTickets({commit}, data) {
        try {
            let response = await axios.get(`/api/tickets`, data)
            commit('setTickets', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
                return false
        }
    },

    async getTicket({commit}, id) {
        try {
            let response = await axios.get(`/api/tickets/${id}/show`)
            commit('setTicket', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getTopics({commit}, data) {
        try {
            let response = await axios.get(`/api/topics`, data)
            commit('setTopics', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async selectTicket({commit}, id) {
        commit('setTicketLoading', true)
        try {
            let response = await axios.post(`/api/tickets/${id}/select`)
            commit('setTicketLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async updateTicket({commit}, data) {
        commit('setTicketLoading', true)
        commit('setTicketErrors', {})
        try {
            let response = await axios.put(`/api/tickets/${data.id}/update`, data)
            commit('setTicketLoading', false)
            commit('setTicketErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setTicketLoading', false)
            commit('setTicketErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}