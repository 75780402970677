const tickets =  [
    {
        path: '/tickets',
        name: 'tickets.index',
        component: () => import('@/pages/Ticket/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            need_project: false,
            layout: 'content',
            title: "Tiketlar",
        }
    },
    {
        path: '/tickets/create',
        name: 'tickets.create',
        component: () => import('@/pages/Ticket/CreatePage.vue'),
        meta: {
            need_project: false,
            requiresAuth: true,
            layout: 'content',
            title: "Tiket yaratish",
        }
    },
    {
        path: '/tickets/:id/show',
        name: 'tickets.show',
        component: () => import('@/pages/Ticket/EditPage.vue'),
        meta: {
            need_project: false,
            requiresAuth: true,
            layout: 'content',
            title: "Tiket yaratish",
        }
    },
]

export default tickets